.expense-item {
    display: flex;
    background: #4b4b4b;
    margin: 1rem 0;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
}

.expense-item__description{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1;
}

.expense-item__description h2{
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
    color: white;
}

.expense-item__price{
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #40005d;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;

}

@media (min-width: 580px) {
    .expense-item__description{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }
    
    .expense-item__description h2{
        font-size: 1.25rem;
    }
    
    .expense-item__price{
        font-size: 1.25rem;
        padding: 0.5rem 1.5rem;
    }
}