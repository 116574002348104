.expense-date{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 10px;
    width: 5.5rem;
    height: 5.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    color: white;
    justify-content: center;
}

.expense-date__month{
    font-size: 0.75rem;
    font-weight: bold;
}

.expense-date__year{
    font-size: 0.75rem;
}

.expense-date__day{
    font-size: 1.5rem;
    font-weight: bold;
}